@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,500;1,400&display=swap');

.alchemy {
	padding:30px;
    background:url('./images/bg-flex-vault.png') top center, linear-gradient(rgb(39, 15, 188), rgb(27, 89, 183));
	z-index: 0;
	overflow: hidden;
}

@media only screen and (max-width: 600px) {
	.alchemy {
		background:url('./images/bg-flex-vault-m.png') top center, linear-gradient(rgb(39, 15, 188), rgb(27, 89, 183));
		background-size:contain;
	}
}

section {max-width:1140px;margin: auto;}

body {background: no-repeat center/80% url('./images/bg-flex-vault.png');}

p {margin: 15px auto;}

h3.hdnum {background:#FFFFFF;height:90px;width:90px;color:#000000;padding-top:20px;border-radius:100%;display:inline-block;font-size:34px;font-weight:900px;margin-bottom:15px;}
.whiterborder {border:#FFFFFF solid 3px;border-radius: 25px;padding:35px;margin:0 auto;}
.whiterborderno {padding:35px;margin:0 auto;}
.btwhite {position:absolute;top:40px;right:60px;z-index: 10;}
.btwhite2 {position:absolute;top:40px;right:160px;z-index: 10;}
.btwhite3 {position:absolute;top:40px;right:260px;z-index: 10;}
.btwhite1 {background:#FFFFFF;color:#000000;padding:10px 15px;border-radius: 36px;margin:0 auto;font-size:26px;width:200px;display: inline;flex: 1;}
.btwhite1 {flex: 1;}
.btwhite10 {flex: 1;}
.btwhite1:first-child {margin-right: 20px;}
.btwhite1:hover {background:#F2F2F2;}
.btwhite1 img {text-align: center;margin:10px auto;}
.martop {padding-top:90px;}

ul li {display:inline-block; background:#f50e95;padding:15px 30px;margin:10px;font-size:20px;font-weight:600;border-radius: 34px;;}

input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

input#fname, input#lname, input#city {
  width: 48%;margin-right:2%;
}

input#state {
  width: 18%;margin-right:2%;
}

input#zipcode {
  width: 30%;
}

textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

label {color:#FFFFFF;}

::placeholder {
  color: #3a3f99;
  font-size: 1.5em;
  text-transform: uppercase;
}

input[type=submit] {background-color: #000000;color:#FFFFFF;font-size: 1.5em;text-transform: uppercase;font-weight:700;width:30%;text-align:center;margin:0 auto;}

.flex1 {display: flex;}

.sectitle {max-width:840px;text-align: center;}

@media only screen and (max-width: 600px) {
.flex1 {display: flex;flex-direction:column;}
.flex-col {width:380px;}
.btwhite1:first-child {margin-right: 90px;margin-bottom: 15px;}
}